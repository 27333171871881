import {useState, useContext, useRef, useEffect} from "react";
import { Panel, PlaceholderArea, Tablist, Toolbar } from "../vs-code";
import { Screen } from "../vs-code";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Alert, Button, Rate } from "antd";
import { Auth } from "../../contexts/ridersUserContext";
import { FaRobot, FaHeart } from "react-icons/fa";
import { restartSession } from "../../api";
import { getCookie, setCookie } from "../shared/Cookie";
import { useParams } from "react-router-dom";
import SplitPane from 'react-split-pane';
import { FormattedMessage } from "react-intl";

const EditorLayout = () => {
  const [panelOptions, setPanelOptions] = useState<any>({});
  const [reated, setReated] = useState<boolean>(false);
  const [alertHover, setAlertHover] = useState<boolean>(false);
  const splitPaneRef = useRef();
  const [isResizing, setIsResizing] = useState(false);

  const {
    workspacelayout,
    workspaceCounter,
    workspaces,
    tabRelocation,
    setIsDragging,
    mousePosition,
    addWorkspace,
  } = useContext<any>(Screen.context);

  const { mode, shutDown, community, accessTokenKey, projectType } =
    useContext<any>(Auth.context);

  const params = useParams();

  const onDragStart = () => {
        setIsResizing(true);
    }

    const onDragEnd = () => {
      setIsResizing(false);
    };

    // inject drag button to split
    useEffect(() => {
      const resizer = document.querySelector(".Resizer");
      if (resizer && !resizer?.querySelector("button")) {
        resizer?.classList.add("flex", "w-5", "items-center", "justify-center");
        const dragButton = document.createElement("button");
        dragButton.classList.add("bg-black", "rounded-full", "cursor-default");
        const ul = document.createElement("ul");
        ul.classList.add(
          "flex",
          "flex-col",
          "w-full",
          "h-fit",
          "py-2",
          "px-1.5",
          "space-y-1.5"
        );
        const li = document.createElement("li");
        li.classList.add("w-1.5", "h-1.5", "rounded-full", "bg-white");
        for (let i = 1; i <= 3; i++) {
          const li = document.createElement("li");
          li.classList.add("w-1.5", "h-1.5", "rounded-full", "bg-white");
          ul.appendChild(li);
        }
        dragButton.appendChild(ul);
        resizer?.appendChild(dragButton);
      }
    });

    useEffect(() => {
      let resizeTimer: any;

      if (window.Blockly && window.Blockly.WidgetDiv) {
        window.Blockly.WidgetDiv.hide();
      }  

      if (isResizing) {
        resizeTimer = setInterval(() => {
          window.getBlocklyWorkspace?.((workspace: any) => {
            window.Blockly.svgResize(workspace);
          });
        }, 10);
      } else {
        clearInterval(resizeTimer);
        window.getBlocklyWorkspace?.((workspace: any) => {
          window.Blockly.svgResize(workspace);
          // const selectedBlock = window.Blockly.selected;
          // if (selectedBlock) {
          //   selectedBlock.unselect()
          //   selectedBlock.inputList.forEach((input : any) => {
          //     input.fieldRow.forEach((field : any) => {
          //       if (field instanceof window.Blockly.FieldTextInput || field instanceof window.Blockly.FieldNumber) {
          //         field.getInputElement().blur(); // Odaklanmayı kaldır
          //       }
          //     });
          //   });
          //   const inputElements = selectedBlock.inputList.flatMap((input: any) => input.fieldRow);
          //   inputElements.forEach((field: any) => {
          //     if (field instanceof window.Blockly.FieldTextInput || field instanceof window.Blockly.FieldNumber) {
          //       const inputElement = field.getInputElement();
          //       inputElement.click();  // Focus'u kaldır
          //     }
          //   });
          // }
        });
      }
      return () => clearInterval(resizeTimer);
    }, [isResizing]);

  return (
    <div className={`fit flex`}>
      <Toolbar
        setPanel={setPanelOptions}
        panel={panelOptions}
        className="flex-shrink-0"
      />
      <Panel
        title={panelOptions.title}
        open={panelOptions.open}
        children={panelOptions.children}
        settings={panelOptions.settings}
        className="flex-shrink-0"
      />
      <div className="flex-grow max-h-full flex flex-col">
        {mode === "preview" && (
          <Alert
            onMouseEnter={() => setAlertHover(true)}
            onMouseLeave={() => setAlertHover(false)}
            message={
              <span className="text-sm p-0 m-0">
                {!alertHover
                  ? "You are currently in preview mode, show more information."
                  : "You are currently in preview mode. | You can only view and edit the project. But you can't save and publish the project."}
              </span>
            }
            banner
            closable
            className="h-8"
          />
        )}

        {/*// @ts-ignore*/}
        <DragDropContext
          onDragEnd={async (result) => {
            if (mousePosition !== undefined) {
              await addWorkspace();
              tabRelocation(
                result.draggableId,
                result.source?.droppableId,
                "B",
                0
              );
            } else {
              tabRelocation(
                result.draggableId,
                result.source?.droppableId,
                result.destination?.droppableId,
                result.destination?.index
              );
            }
            setIsDragging(false);
          }}
          onBeforeDragStart={(i) => {
            console.log(workspaceCounter);
            setIsDragging(true);
          }}
        >
          <div className={`w-full relative overflow-hidden h-full`}>
            <div
              className={`fit grid grid-cols-2 grid-rows-2`}
              style={{ gridTemplateAreas: workspacelayout }}
            >
              {/*@ts-ignore*/}
              <SplitPane split="vertical" defaultSize={community === "deneyap" ? "30%" : "50%"} ref={splitPaneRef} onDragStarted={onDragStart} onDragFinished={onDragEnd}>
                {workspaces.map((workspace: any, index: number) => (
                  // @ts-ignore
                  <Droppable
                    key={workspace.id}
                    droppableId={workspace.id}
                    direction="horizontal"
                  >
                    {(provided: any, snapshot: any) => {
                      return (
                        <Tablist
                          key={index}
                          style={{
                            gridArea: workspace.id,
                          }}
                          tabs={workspace.tabs}
                          workspaceId={workspace.id}
                          provided={provided}
                          isResizing={isResizing}
                        />
                      );
                    }}
                  </Droppable>
                ))}
              </SplitPane>
            </div>

            <PlaceholderArea mousePosition={mousePosition} />
          </div>
        </DragDropContext>

        {projectType === "electronics" && (
          <div className="flex justify-end text-white h-2"></div>
        )}
      </div>

      {shutDown && community === "riders" && (
        <div
          className="absolute w-screen h-screen top-0 left-0 flex justify-center items-center"
          id="cover-screen-expires"
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(4px)",
            zIndex: 999,
          }}
        >
          <Alert
            message={<FormattedMessage id="sessionExpired" />}
            description={
              <div className="flex flex-col space-y-4">
                {/* <p><FormattedMessage id="sessionExpired"/></p> */}
                <span>
                  {/* <p>
                    <FormattedMessage id="appreciateFeedback"/>
                  </p> */}

                  <p className="pt-2">
                    <FormattedMessage id="restartSession" />
                  </p>
                  <span className="pr-4 pt-4 flex items-center justify-between w-full pointer-events-auto">
                    <Button
                      onClick={async () => {
                        const token = params["token"];
                        const data = await restartSession(token || "");
                        console.log(data, accessTokenKey);
                        setCookie(
                          accessTokenKey,
                          data,
                          new Date().getTime() + 1000 * 60 * 3
                        );
                        window.location.reload();
                      }}
                    >
                      <FormattedMessage id="restart" />
                    </Button>
                    {/* {!reated ? (
                        <Rate
                            character={<FaRobot/>}
                            allowHalf
                            onChange={() => setReated(true)}
                        />
                    ) : (
                        <FaHeart className="text-red text-xl"/>
                    )} */}
                  </span>
                </span>
              </div>
            }
            type="info"
            className="w-96"
            showIcon
          />
        </div>
      )}
    </div>
  );
};

export default EditorLayout;
