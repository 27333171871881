import React, { useState, useContext, useEffect } from "react";
import { VscClose, VscCircleFilled } from "react-icons/vsc";
import {
  Editor,
  IconSelector,
  Screen,
  Terminal,
  MiniBrowser,
  FileSystem,
} from "./";
import { BEditor as DeneyapBlock } from "./DeneyapBlock/Blockly";
import { BEditor as RidersBlock } from "./RidersBlock/Blockly";
import { BEditor } from "./Blockly/Blockly";
import { Draggable } from "react-beautiful-dnd";

export const Tablist = ({
  style,
  tabs,
  workspaceId,
  provided,
  isResizing,
}: any) => {
  const {
    changeFileInWorkspace,
    setActiveInWorkspace,
    setEditorFocus,
    readWorkspaceFile,
    projectConfig,
    project,
    isTerminalOpen,
    godotContainerRef,
    documentationRef,
    setBlocklyXML,
    setPythonCode,
  } = useContext<any>(Screen.context);

  const { readFile, writeFile, inProcess } = useContext<any>(
    FileSystem.context
  );

  return (
    <div
      style={style}
      className="bg-vs-light-panel dark:bg-vs-dark-panel fit overflow-hidden flex flex-col"
      ref={provided.innerRef}
      data-rbd-droppable-context-id={
        provided.droppableProps["data-rbd-droppable-context-id"]
      }
      data-rbd-droppable-id={provided.droppableProps["data-rbd-droppable-id"]}
    >
      <Tabs placeholder={provided.placeholder}>
        {tabs.map((tab: any, index: number) => {
          return (
            <Tab
              index={index}
              key={tab.id}
              icon={IconSelector(tab.type)}
              value={tab.title}
              isSaved={
                ["python", "blockly", "arduino"].includes(tab.type)
                  ? !inProcess
                  : tab.isSaved
              }
              isSelected={tab.isActive}
              onClick={() => setActiveInWorkspace(workspaceId, tab.id)}
              workspaceId={workspaceId}
              tabId={tab.id}
              closable={tab.closable}
            />
          );
        })}
      </Tabs>

      <div className="bg-vs-light-backgorund dark:bg-vs-dark-background fit">
        {tabs.map((tab: any) => {
          switch (tab.type) {
            case "simulation":
              return (
                  <div
                      className={`fit relative ${
                          tab.isActive === true ? "flex" : "hidden"
                      } flex-col justify-center items-center bg-black text-white`}
                      key={tab.id}
                  >
                    <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          zIndex: isResizing ? 1000 : -1,
                        }}
                    />
                    <MiniBrowser
                        key={tab.id}
                        src={project.simulationUrl}
                        refrence={godotContainerRef}
                    />

                    <Terminal
                        className={`opacity-70 absolute w-full transition-all left-0 h-72`}
                        style={{
                          height: 288,
                          bottom: isTerminalOpen ? 0 : -288,
                        }}
                    />
                  </div>
              );
            case "iframe":
              return (
                  <div className="fit relative" key={tab.id}>
                    <div
                        style={{
                          position: "absolute",
                          // top: "20px",
                          left: 0,
                          width: "100%",
                          height: "100%",
                          zIndex: isResizing ? 1000 : -1,
                        }}
                    />
                    <MiniBrowser
                        src={project.documentationUrl}
                        className={`${tab.isActive ? "block" : "hidden"}`}
                        key={tab.id}
                        refrence={documentationRef}
                    />
                  </div>
              );
            case "blockly":
              if (tab.isActive) {
                if (project.language === "ino") {
                  return (
                      <RidersBlock
                          project={project}
                          projectConfig={projectConfig}
                          setCode={(code: string) => {
                            if (!code) {
                              return;
                            }
                            setPythonCode(code);
                          }}
                          setXML={setBlocklyXML}
                          key={tab.id}
                          className={`fit`}
                          file_path={tab.path}
                      />
                  );
                }
                if (project.language === "cpp") {
                  return (
                      <DeneyapBlock
                          project={project}
                          projectConfig={projectConfig}
                          setCode={(code: string) => {
                            if (!code) {
                              return;
                            }
                            setPythonCode(code);
                          }}
                          setXML={setBlocklyXML}
                          key={tab.id}
                          className={`fit`}
                          file_path={tab.path}
                      />
                  );
                }
                return (
                    <BEditor
                        project={project}
                        projectConfig={projectConfig}
                        setCode={(code: string) => {
                          if (!code) {
                            return;
                          }
                          setPythonCode(code);
                        }}
                        setXML={setBlocklyXML}
                        key={tab.id}
                        className={`fit`}
                        file_path={tab.path}
                    />
                );
              } else {
                return null;
              }
            default:
              return (
                  <Editor
                      file_path={tab.path}
                      code={tab.content}
                      setCode={(code: string) => {
                        changeFileInWorkspace(workspaceId, tab.id, code);
                        writeFile(tab.path, code);
                      }}
                      className={`fit ${tab.isActive ? "block" : "hidden"}`}
                      setMount={(editor: any) => {
                        editor._focusTracker.onChange(() => {
                          if (editor._focusTracker.hasFocus()) {
                            setEditorFocus({
                              workspaceId,
                              tabId: tab.id,
                              title: tab.title,
                            });
                          } else {
                            setEditorFocus(undefined);
                          }
                        });
                      }}
                      key={tab.id}
                  />
              );
          }
        })}
      </div>
    </div>
  );
};

const Tabs: React.FC<{ children: React.ReactNode; placeholder: any }> = ({
  children,
  placeholder,
}) => {
  return (
    <div className="w-full flex bg-arduino-green-light dark:bg-vs-dark-panel">
      {children}
      {placeholder}
    </div>
  );
};

const Tab: React.FC<{
  icon: React.ReactNode;
  value: string;
  isSaved: boolean;
  isSelected: boolean;
  workspaceId: string;
  tabId: string;
  closable?: boolean;
  onClick: () => void;
  index: number;
}> = ({
  icon,
  value,
  isSaved,
  isSelected,
  onClick,
  workspaceId,
  tabId,
  closable,
  index,
}) => {
  closable = closable === undefined ? true : closable;

  const [hoverClose, setHoverClose] = useState(false);

  const { removeFileFromWorkspace } = useContext<any>(Screen.context);

  return (
    // @ts-ignore*
    <Draggable key={tabId} draggableId={tabId} index={index}>
      {(provided) => (
        <span
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={`top-10 flex items-center px-4 space-x-2 cursor-pointer border-r border-vs-light-panel dark:border-vs-dark-panel text-black dark:text-white mt-2 dark:mt-0 ml-2 dark:ml-0 rounded-t-md dark:rounded-t-none ${
              isSelected
                ? "bg-vs-light-background dark:bg-vs-dark-background"
                : "bg-vs-light-toolbar dark:bg-vs-dark-toolbar"
            }`}
            onClick={onClick}
            onMouseEnter={() => {
              if (isSaved) {
                setHoverClose(true);
              }
            }}
            onMouseLeave={() => {
              if (isSaved) {
                setHoverClose(false);
              }
            }}
          >
            {icon || null}
            <span className="flex-grow py-1.5 text-xs">{value}</span>
            <span
              className={`${
                !closable
                  ? ""
                  : isSelected
                  ? "hover:bg-vs-light-toolbar dark:hover:bg-vs-dark-toolbar"
                  : "hover:bg-vs-light-panel dark:hover:bg-vs-dark-panel"
              } rounded-sm p-0.5`}
              onMouseEnter={() => setHoverClose(true)}
              onMouseLeave={() => setHoverClose(false)}
              onClick={(e) => {
                if (closable) {
                  e.stopPropagation();
                  removeFileFromWorkspace(workspaceId, tabId);
                }
              }}
            >
              {hoverClose && closable ? (
                <VscClose />
              ) : isSaved ? (
                <span className="px-1.75"></span>
              ) : (
                <VscCircleFilled />
              )}
            </span>
          </div>
        </span>
      )}
    </Draggable>
  );
};
